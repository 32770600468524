<template>
  <button ref="openModalButton" type="button" hidden data-bs-toggle="modal" data-bs-target="#profileModal"></button>

  <div class="modal fade" ref="profileModal" id="profileModal" tabindex="-1" data-bs-backdrop="static" aria-hidden="true" data-bs-focus="false">
    <div class="modal-dialog pretorModal">
      <div class="modal-content">

        <div class="modal-header" style="color:gray">
          <b>CAMBIO DE FOTO DE PERFIL</b>
          <button ref="btnCloseProfileModal" type="button" class="btn-close" data-bs-dismiss="modal"
            aria-label="Close"></button>
        </div>

        <div class="modal-body">
          <input ref="fileInput" type="file" accept="image/*" hidden @change="HandleFileChange">

          <div v-if="this.selectedFile == null">
            <div style="margin-bottom: 5px">
              <i class="bi bi-info-circle"></i>
              SELECCIONA LA IMAGEN QUE DESEAS UTILIZAR COMO FOTO DE PERFIL
            </div>

            <div class="pretorForm customFilePicker" @click="OpenFilePicker" style="margin-bottom: 0px !important">
              <!--FILE PICKER -->

              <!--FILE PICKER BUTTON-->
              <label ref="fileLabel" style="font-size: 14px !important;">
                <i class="bi bi-file-earmark"></i>
                SELECCIONAR IMAGEN
              </label>
            </div>
          </div>

          <div v-if="this.selectedFile != null">
            <div style="margin-bottom: 5px">
              <i class="bi bi-info-circle"></i>
              AJUSTA LA IMAGEN A TU GUSTO, UNA VEZ QUE ESTÉS LISTO, DA CLICK EN EL BOTÓN ACTUALIZAR FOTO DE PERFIL
            </div>

            <Cropper ref="cropper" />

            <PretorButton
              :style="'width: 100%; margin-top: 10px;'"
              :icon="'bi bi-upload'"
              :label="'SELECCIONAR OTRA FOTO'"
              @click="OpenFilePicker"/>
          </div>
          
        </div>
        <div class="modal-footer custom-modal-footer">
          <PretorButton v-if="this.currentProfilePicture != null"
            :icon="'bi bi-trash'"
            :label="'ELIMINAR MI FOTO DE PERFIL'"
            @click="DeleteProfilePicture"
           />

          <PretorButton v-if="this.selectedFile != null"
            :icon="'bi bi-check-circle'"
            :label="'ACTUALIZAR FOTO DE PERFIL'"
            @click="UpdateProfilePicture"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cropper from './Cropper.vue';
import PretorButton from '../Generic/Button.vue';
import { useStore } from '@/store/DefaultStore.js';
import { inject } from 'vue';
import UserService from '@/services/UserService.js';

export default {
  name: 'ProfileModal',

  components: {
    Cropper, PretorButton
  },

  data() {
    return {
      selectedFile: null,
      currentProfilePicture: null
    }
  },

  setup() {
    const store = useStore();
    const ShowNotification = inject('ShowNotification');
    const ConvertFileToBase64 = inject('ConvertFileToBase64');

    return {
      store, ShowNotification, ConvertFileToBase64
    }
  },

  methods: {
    OpenModal() {
      this.$refs.fileInput.value = '';
      
      this.currentProfilePicture = this.store.LoggedUser.ProfilePictureURL ? this.store.LoggedUser.ProfilePictureURL : null;
      this.selectedFile = this.currentProfilePicture ? this.currentProfilePicture : null;

      if(this.currentProfilePicture != null){
        this.$nextTick(() => {
          this.$refs.cropper.uploadImage(this.currentProfilePicture, true);
        })
      }

      this.$refs.openModalButton.click();
    },

    HandleFileChange() {
      let file = this.$refs.fileInput.files[0];

      this.selectedFile = file ? file : null;
      if(this.selectedFile == null)
        return;

      // If file is not an HEIC, png or jpg file, show error
      if(!['image/jpeg', 'image/png', 'image/heic'].includes(this.selectedFile.type)){
        this.ShowNotification('error', 'EL FORMATO DE LA IMAGEN NO ES VÁLIDO');
        this.selectedFile = null;
        return;
      }

      this.$nextTick(() => {
        this.$refs.cropper.uploadImage(this.selectedFile);
      })
    },

    OpenFilePicker() {
      this.$refs.fileInput.click();
    },

    DeleteProfilePicture(){
      this.store.SetLoader(true);
      UserService.DeleteUserProfilePicture()
      .then(result => {

        this.store.LoggedUser.ProfilePictureURL = null;

        if(this.store.loadedUsersData[this.store.LoggedUser.PKUser]){
          this.store.loadedUsersData[this.store.LoggedUser.PKUser].ProfilePictureURL = null;
          this.store.loadedUsersData[this.store.LoggedUser.PKUser].Initials = result.Initials;
        }

        this.currentProfilePicture = null;

        this.store.SetLoader(false);
        this.ShowNotification('success', 'LA FOTO DE PERFIL SE HA ELIMINADO CORRECTAMENTE');
        this.$refs.btnCloseProfileModal.click();
      })
      .catch(() => {
        this.store.SetLoader(false);
        this.ShowNotification('error', 'OCURRIÓ UN ERROR AL ELIMINAR LA FOTO DE PERFIL');
      });
    },

    async UpdateProfilePicture() {
      let imageFile = await this.$refs.cropper.getCroppedImage();

      this.store.SetLoader(true);

      let formData = new FormData();
      formData.append('Image', imageFile);

      await UserService.UpdateUserProfilePicture(formData)
        .then(result => {
          this.store.LoggedUser.ProfilePictureURL = result.ProfilePictureURL;

          if(this.store.loadedUsersData[this.store.LoggedUser.PKUser]){
            this.store.loadedUsersData[this.store.LoggedUser.PKUser].ProfilePictureURL = result.ProfilePictureURL;
          }

          this.ShowNotification('success', 'LA FOTO DE PERFIL SE HA ACTUALIZADO CORRECTAMENTE');
          this.$refs.btnCloseProfileModal.click();
        })
        .catch(() => {
          this.ShowNotification('error', 'OCURRIÓ UN ERROR AL REALIZAR LA CONVERSIÓN DE LA IMAGEN');
        })
        
        this.store.SetLoader(false);
    },
  }
}
</script>

<style scoped>
b {
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 10px;
}

.customFilePicker {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.customFilePicker input[type="file"] {
  display: none;
}

.customFilePicker label {
  cursor: pointer;
}

.customFilePicker label i {
  margin-right: 5px;
}

.custom-modal-footer{
    display: grid;
    grid-template-columns: auto auto;
    gap: 5px;
    align-items: stretch;
    width: 100%;
}
</style>