<template>
<div></div>
</template>

<script>
import Swal from 'sweetalert2'
import { useStore } from '@/store/DefaultStore.js';

export default{
    name: 'ConfirmationModal',

    setup(){
        const store = useStore();

        return{
            store
        }
    },

    methods: {
        OpenModal(message, definitive, confirmation, value, showCancelButton = true, title = 'CONFIRMAR ACCIÓN'){
            return new Promise((resolve) => {
                Swal.fire({
                    html:`
                    <div style="margin-bottom: 3px; display: flex; justify-content: left; margin-top: 10px;">
                        <b>${title}</b>
                    </div>

                    <button type="button" id="btnClosePopup" class="btn-close btnClosePopup" aria-label="Close"></button>
                    
                    <div style="display: flex; justify-content: left; text-align: left">
                        <span>${message}</span>
                    </div>
                    <div style="text-align: left">
                        ${definitive ? 'Esta acción será definitiva y no podrá cancelarse' : ''}
                    </div>
                    <div style="display: flex; justify-content: right; margin-top: 20px;">

                        ${showCancelButton ? `<button id="customSwalCancelButton" class="pretorButton gray" style="margin-right: 10px;">
                            <i class="bi bi-x-circle" style="margin-right: 5px;"></i>
                            CANCELAR
                        </button>` : ''}
                        <button id="customSwalConfirmButton" class="pretorButton">
                            <i class="bi bi-check-circle" style="margin-right: 5px;"></i>
                            CONFIRMAR
                        </button>
                    </div>`,
                    showCancelButton: false,
                    showConfirmButton: false,
                    customClass: {
                        popup: 'custom-swal'
                    },
                    showClass: {
                        popup: `
                        animate__animated
                        animate__fadeInDown
                        animate__faster
                        `
                    },
                    hideClass: {
                        popup: `
                        animate__animated
                        animate__fadeOutUp
                        animate__faster
                        `
                    },
                    didOpen: () => Swal.getPopup().focus()
                })

                setTimeout(() => {
                    let btnClosePopup = document.getElementById('btnClosePopup');
                    if(btnClosePopup)
                        btnClosePopup.addEventListener('click', () => {
                        Swal.close();
                        resolve(false);
                    });

                    let customSwalCancelButton = document.getElementById('customSwalCancelButton');
                    if(customSwalCancelButton)
                        customSwalCancelButton.addEventListener('click', () => {
                        Swal.close();
                        resolve(false);
                    });

                    let customSwalConfirmButton = document.getElementById('customSwalConfirmButton');
                    if(customSwalConfirmButton)
                        customSwalConfirmButton.addEventListener('click', () => {
                        confirmation(value)
                        .then(()=>{
                            Swal.close();
                            resolve(true);
                        })
                        .catch(() => {
                            Swal.close();
                        });
                    });

                    let titleElement = document.querySelector('.swal2-title');
                    if(titleElement)
                        titleElement.focus();
                }, 100)
            })
        },
    }

}
</script>

<style>
/* Desktop */
@media (min-width: 600px){
    .custom-swal {
        width: 40%;
    }
}

/* Mobile */
@media (max-width: 600px){
    .custom-swal {
        width: 100%;
    }
}

.custom-swal{
    border-radius: 10px;
    padding-bottom: 0px;
}

.swal2-html-container {
    margin: 20px !important;
    margin-top: 10px !important;
}

.btnClosePopup {
    position: absolute;
    right: 15px;
    top: 15px;
}
</style>

