import { defineStore } from 'pinia'

export const useStore = defineStore({  
  id: 'store',
  state: () => (
    { 
      isLoading : false,
      deviceWidth : 0,
      LoggedUser: null,
      isCustomer: false,
      ReloadErrorMessage: null,
      loadedUsersData: [],
      Organizations: [],
      CurrentOrganizationPK: null,
      JustRegistered: false,
      RegisteredFromLogin: false,
      UserToRegisterData: null,
      unpinnedFiles: [],
      isMobile: false,
      isIpad: false,
      lessThan600: false,
      currentPanel: 0,
      isLoginOrRegister: false,
      Actions: {},
      env: 'PRD',

      toastOptions : {
        position: "top-right",
        timeout: 3000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false
      },       

      navigationVars : {
        agreements: {},
        home: {},
        files: {},
        tasks: {}
      }
    }
  ),
  actions: {
    SetOrganizations(Organizations){
      this.Organizations = [...Organizations];
    },

    SetLoader(isLoading) {
      this.isLoading = isLoading;
    },

    SetDeviceWidth(deviceWidth) {
      this.deviceWidth = deviceWidth;

      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      let isIpad = (/Macintosh/i.test(userAgent) || /iPad/i.test(userAgent)) && navigator.maxTouchPoints > 0;
      this.isIpad = isIpad;
    },

    SetLoggedUser(userData){
      this.LoggedUser = userData;

      if(userData == null)
        return;

      this.isCustomer = userData.Role == 'CUSTOMER';
    },

    SetUserActions(actions) {
      this.Actions = actions;
    }
  }
})