import { createRouter, createWebHistory } from 'vue-router'
import { useStore } from '@/store/DefaultStore.js';
import SessionHandler from '@/authentication/SessionHandler';
import ShowNotification from '@/services/ToastService';
import UserService from '@/services/UserService';
import AuthenticationService from '@/services/AuthenticationService';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import('../components/Views/Login/LoginView.vue'),
    },
    {
      path: '/inicio',
      name: 'home',
      component: () => import('../components/Views/Home/HomeView.vue'), 
    },
    {
      path: '/expedientes',
      name:'files',
      component: () => import('../components/Views/Files/FilesView.vue'),
    },
    {
      path: '/expediente/:PKFile/:PKTask?/:PKDocument?/:PKAgreement?',
      name: 'fileDetail',
      component: () => import('../components/Views/Files/FileDetail/FileDetail.vue'),
      props: true
    },
    {
      path: '/calendario',
      name: 'calendar',
      component: () => import('../components/Views/Calendar/CalendarView.vue'),
    },
    {
      path: '/acuerdos/:PKAgreement?',
      name: 'agreements',
      component: () => import('../components/Views/Agreements/AgreementsView.vue'),
      props: true
    },
    {
      path: '/usuarios',
      name: 'users',
      component: () => import('../components/Views/Users/UsersView.vue'),
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('../components/Views/Login/RegisterView.vue'),
    },
    {
      path: '/organizaciones',
      name: 'organizations',
      component: () => import('../components/Views/Organizations/OrganizationsView.vue'),
    },
    {
      path: '/lecturaAutomaticaDeAcuerdos',
      name: 'agreementsReadingProcessLog',
      component: () => import('../components/Views/Agreements/AgreementsReadingProcessLog/AgreementsReadingProcessLogView.vue'),
    },
    {
      path: '/aprobacionDeAcuerdos',
      name: 'agreementsApprovals',
      component: () => import('../components/Views/Agreements/AgreementsApprovals/AgreementsApprovalsView.vue'),
    },
    {
      path: '/configuracion/:SettingName?',
      name: 'settings',
      component: () => import('../components/Views/Settings/SettingsView.vue'),
      props: true
    },
    {
      path: '/notificacionDeAcuerdos',
      name: 'agreementsNotifications',
      component: () => import('../components/Views/Agreements/AgreementsNotification/AgreementsNotificationView.vue'),
    },
    {
      path: "/:catchAll(.*)",
      name:'notFound',
      component: () => import('../components/Views/Home/HomeView.vue'),
    },
  ]
})

router.beforeEach(async (to, from, next) => {
  let goToPage = 'login';
  const store = useStore();

  try
  {
    store.currentPanel = to.name;

    // Si va al login o register no necesita validaciones
    store.isLoginOrRegister = to.name == 'login' || to.name == 'register' || to.name == 'notFound';
    if(to.name == 'notFound'){
      next({name: 'login'});
      return;
    }

    if(store.isLoginOrRegister){
      next();
      return;
    }

    if(to.name == 'home' && store.isCustomer){
      router.push({name: 'agreements'});
      next();
      return;
    }

    // Si ya esta loggeado no necesita validaciones
    if(store.LoggedUser != null){
      store.SetLoader(true);
      await AuthenticationService.GetUserHasAccessToScreen({route: to.name})
      .then(data => {
        store.SetLoader(false);

        if(!data){
          goToPage = from.name || 'login';
          throw new Error('NO TIENES PERMISO PARA ACCEDER A ESTA PANTALLA');
        }
      });

      next();
      return;
    }

    await SessionHandler.GetAccessToken()
    .then(data => {
      if(!data){
        throw new Error('NO SE HA INICIADO SESIÓN')
      }
    });

    await SessionHandler.GetMFA()
    .then(data => {
      if(data == null){
        throw new Error('DEBE INGRESAR EL DOBLE FACTOR DE AUTENTICACIÓN');
      }
    });

    store.SetLoader(true);
    if(store.LoggedUser == null){
      await UserService.SetUserInitialData();
    }

    await AuthenticationService.GetUserHasAccessToScreen({route: to.name})
    .then(data => {
      store.SetLoader(false);

      if(data){
        return;
      }

      if(store.isCustomer && to.name != 'files'){
        goToPage = 'files';
      }

      if(!store.isCustomer && to.name != 'home'){
        goToPage = 'home';
      }

      throw new Error('NO TIENES PERMISO PARA ACCEDER A ESTA PANTALLA');
    });

    next();
  }
  catch(error){
    store.SetLoader(false);
    ShowNotification.Error(error.message);
    next({name: goToPage});
    throw error;
  }
});

export default router