<template>
    <div class="chatContainer" ref="chatContainer">
        <div class="header" ref="header">
            <div class="headerTitle">
                ASISTENTE DE INTELIGENCIA ARTIFICIAL
            </div>
            <button type="button" class="btn-close" aria-label="Close" @click="CloseAiAssistant()"></button>
        </div>

        <div class="helpButtonWrapper" v-show="messages.length > 0">
            <button class="helpButton" @click="ToggleInstructions">
                <i :class="{'bi bi-question-circle' : !showInstructions, 'bi bi-arrow-left' : showInstructions }"></i>
            </button>
        </div>

        <div class="body" ref="body" v-show="!showInstructions && (messages.length > 0 || firstChatLoad)">
            <div class="fullWidth aiAssistantCentered assistantLoadingWrapper" :hidden="endOfChat">
                <div ref="loading" class="spinner-border assistantLoading"></div>
            </div>

            <AssistantMessage v-for="message in messages" :key="message" :message="message" />
            <AssistantMessage v-show="loading" :message="loadingMessage" :loadingMessage="true" />

            <div ref="chatBottom" class="hiddenObserverDiv"></div>
        </div>

        <div ref="instructionsBody" class="body instructions" v-show="showInstructions || (messages.length == 0 && !firstChatLoad)">
            <div class="fullWidth aiAssistantCentered" style="height: auto;">
                <div class="instructionsBotIconWrapper aiAssistantCentered">
                    <img src="../../images/BotIcon48.png">
                </div>
            </div>

            <div class="instructionsCard">
                <div class="instructionsHeader">
                    <i class="bi bi-info-circle"></i>
                    ¿Cómo funciona el asistente?
                </div>
                ¡Bienvenido! El Asistente de Inteligencia Artificial está aquí para <b>ayudarte a resolver
                    dudas y manejar tareas de manera eficiente.</b><br/>
                Puedes interactuar con él para recibir asistencia en tiempo real, ya sea para preguntas generales o para
                problemas específicos que enfrentes.
            </div>

            <div class="instructionsCard">
                <div class="instructionsHeader">
                    <i class="bi bi-chat-dots"></i>
                    ¿Cómo comunicarme con el asistente?
                </div>
                Para comunicarte con el asistente, simplemente escribe tu mensaje en el cuadro de texto y da click en el
                botón de enviar.<br/>
                Trata de ser lo más <b>claro y específico</b> posible para obtener respuestas precisas.
            </div>

            <div class="instructionsCard">
                <div class="instructionsHeader">
                    <i class="bi bi-paperclip"></i>
                    El asistente puede analizar archivos
                </div>
                Puedes adjuntar archivos junto a tus mensajes para que sean <b>analizados por el asistente</b>.<br/>
                El asistente puede analizar <b>imagenes, documentos de Word, PDFs y archivos de texto</b>.<br/>
                Toma en cuenta que las imágenes dentro de documentos Word y PDF no pueden ser analizadas.
            </div>
        </div>

        <transition name="fade">
            <div ref="goToBottom" class="aiAssistantCentered fullWidth goToBottomBurronWrapper" v-show="messages.length > 0 && !isViewingBottom && !showInstructions">
                <button class="goToBottomButton" @click="GoToBottom">
                    <i class="bi bi-arrow-down"></i>
                </button>
            </div>
        </transition>
        <div class="footer" v-show="(!showInstructions && messages.length > 0) || messages.length == 0">
            <div class="messageBar" ref="messageBar">
                <div class="filesContainer" :class="{ 'empty' : files.length == 0 }">
                    <button v-if="files.length > 0" @click="ClearAttachments" class="iconButton clearFilesButton"
                        tabindex="-1" title="DESELECCIONAR TODOS">
                        <i class="bi bi-x"></i>
                    </button>
                    <div class="selectedFile" v-for="file in files" :key="file.name">
                        <div class="icon aiAssistantCentered" :class="allowedMimeTypes[file.type].class">
                            <i :class="allowedMimeTypes[file.type].icon"></i>
                        </div>
                        {{ file.name }}
                        <button class="removeFileButton aiAssistantCentered" @click="RemoveFile(file)">
                            <i class="bi bi-x"></i>
                        </button>
                    </div>

                    <input type="file" ref="fileInput" multiple hidden @change="AttachmentsChangeHandler" />
                </div>
                <div v-if="maxAllowedCharactersExceeded" class="warningsWrapper">
                    <label class="required">CANTIDAD MÁXIMA DE CARACTERES PERMITIDOS: {{ maxAllowedCharacters }}</label>
                </div>
                <div class="messageInputWrapper">
                    <div class="buttonWrapper">
                        <button class="iconButton" tabindex="-1" @click="OpenAttachments" title="ADJUNTAR ARCHIVOS"
                            :disabled="files.length >= maxAmountOfFiles">
                            <i class="bi bi-paperclip"></i>
                        </button>
                    </div>
                    <div class="textAreaWrapper">
                        <textarea ref="messageInput" v-model="messageValue" id="auto-growing-textarea"
                            placeholder="Envía un mensaje al asistente">
                        </textarea>
                    </div>
                    <div class="buttonWrapper">
                        <button class="iconButton postMessageButton" tabindex="-1" @click="AddAssistantMessage"
                            :title="messageValueLength == 0 ? 'ESCRIBE UN MENSAJE PARA PODER ENVIARLO' : 'ENVIAR MENSAJE'"
                            :disabled="sendMessageDisabled"
                            :class="{'disabled' : messageValueLength == 0 || loading}">
                            <i class="bi bi-arrow-up"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AssistantService from '@/services/AssistantService';
import ToastService from '@/services/ToastService';
import AssistantMessage from './AssistantMessage.vue';
import { useStore } from '@/store/DefaultStore.js';

export default {
    name: 'AiAssistant',
    components: {
        AssistantMessage
    },
    setup(){
        const store = useStore();

        return {
            store
        }
    },
    data() {
        return {
            showInstructions: false,
            messageValue: '',
            loading: false,
            messages: [],
            chatObserver: null,
            headerObserver: null,
            instructionsObserver: null,
            assistantObserver: null,
            chatHeight: 0,

            chatBottomObserver: null,
            isViewingBottom: true,
            
            maxFileSizeInMb: 100,
            maxAmountOfFiles: 10,

            loadingObserver: null,
            endOfChat: false,
            loadingChat: true,
            firstChatLoad: true,
    
            maxAllowedCharacters: 50000,
            files: [],
            allowedMimeTypes: {
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document' : {
                    isImage: false,
                    'icon': 'bi bi-file-earmark-text',
                    'class' : 'Word'
                },
                'application/pdf' : {
                    isImage: false,
                    'icon': 'bi bi-file-earmark-text',
                    'class' : 'PDF'
                },
                'text/plain' : {
                    isImage: false,
                    'icon': 'bi bi-file-earmark-text',
                    'class' : 'Text'
                },
                'image/jpeg' : {
                    isImage: true,
                    'icon': 'bi bi-image',
                    'class' : 'Image'
                },
                'image/png' : {
                    isImage: true,
                    'icon': 'bi bi-image',
                    'class' : 'Image'
                }
            },

            loadingMessage : {
                content: `<div class="loading-dots aiAssistantCentered">
                    <div></div>
                    <div></div>
                    <div></div>
                    </div>`,
                isUserMessage: false,
                files: []
            }
        };
    },

    computed: {
        messageValueLength() {
            return this.messageValue.trim().length;
        },

        maxAllowedCharactersExceeded() {
            return this.messageValue.trim().length > this.maxAllowedCharacters;
        },

        sendMessageDisabled() {
            return this.loading || this.messageValueLength == 0 || this.maxAllowedCharactersExceeded;
        }
    },

    mounted() {
        // Observer to resize the height of the text area when writting on it
        const textarea = document.getElementById('auto-growing-textarea');
        textarea.addEventListener('input', function() {
            let isMaxed = this.scrollHeight > parseInt(window.getComputedStyle(this).getPropertyValue('max-height'), 10);
            if (isMaxed) {
                return;
            }

            this.style.height = '40px';
            this.style.height = this.scrollHeight + 'px';
        });

        // Event to trigger Send Message when enter pressed
        let AddAssistantMessage = this.AddAssistantMessage;
        document.getElementById('auto-growing-textarea').addEventListener('keydown', function(event) {
            if (event.key === 'Enter' && !event.shiftKey) {
                event.preventDefault();
                AddAssistantMessage();
            }
        });

        // Observer that resize the body when the message bar changes height
        let observer = this.GetResizeObserver(this.RecalculateBodyHeight);

        this.headerObserver = observer;
        this.chatObserver = observer;

        this.headerObserver.observe(this.$refs.header);
        this.chatObserver.observe(this.$refs.messageBar);

        // Observer that check if the user is viewing the bottom of the chat (used for auto scroll)
        this.chatBottomObserver = this.GetIsViewedObserver((isViewingBottom) => {
            this.isViewingBottom = isViewingBottom;
        });
        this.chatBottomObserver.observe(this.$refs.chatBottom);
        
        // Observer to load more messages when the user reaches the top of the chat
        let container = this.$refs.body;
        container.addEventListener('scroll', this.GetScrollObserver());
        this.loadingObserver = this.GetIsViewedObserver(async (isIntersecting) => {
            if(isIntersecting){
                await this.GetConversation(!this.firstChatLoad);

                // Restart the observer
                this.loadingObserver.disconnect();

                if(!this.endOfChat){
                    setTimeout(() => {
                        if(this.$refs.loading){
                            this.loadingObserver.observe(this.$refs.loading);
                        }
                    }, 1000);
                }
            }
        });
        this.loadingObserver.observe(this.$refs.loading);

        // Mobile only
        if(!this.store.isMobile){
            return;
        }

         // Observer that checks if the assistant is being viewed
         let options = {
            threshold: 0.01
        };
        this.assistantObserver = this.GetIsViewedObserver((isViewingAssistant) => {
            if(!isViewingAssistant && !this.firstChatLoad){
                this.ResetSize();
            }
        }, options);
        //Set the options to only work if completely dissapears
        this.assistantObserver.observe(this.$refs.chatContainer, options);

        this.mobileHeaderObserver = this.GetIsViewedObserver((isViewingHeader) => {
            if(!isViewingHeader && !this.firstChatLoad){
                this.ResetSize();
            }
        }, options);
        //Set the options to only work if completely dissapears
        this.assistantObserver.observe(this.$refs.header, options);
    },

    beforeUnmount() {
        this.chatObserver.disconnect();
        this.chatBottomObserver.disconnect();
        this.loadingObserver.disconnect();
        this.headerObserver.disconnect();

        if(this.store.isMobile){
            this.assistantObserver.disconnect();
            this.mobileHeaderObserver.disconnect();
        }
    },

    methods: {
        ToggleInstructions() {
            this.showInstructions = !this.showInstructions;
        },

        GoToBottom() {
            this.ScrollToBottom(true);
        },

        RecalculateBodyHeight() {
            let messageBar = this.$refs.messageBar.clientHeight;
            let headerHeight = this.$refs.header.clientHeight;

            // 20 px because of padding and margin
            this.$refs.body.style.height = `calc(100% - ${messageBar}px - ${headerHeight}px - 20px)`;
            // Applies it to the instructions body too
            this.$refs.instructionsBody.style.height = `calc(100% - ${messageBar}px - ${headerHeight}px)`;

            let goToBottomButton = this.$refs.goToBottom;
            goToBottomButton.style.bottom = `${messageBar + 15}px`;

            // Scroll to the bottom of the chat (only if the user is viewing the bottom)
            if(this.isViewingBottom){
                this.ScrollToBottom();
            }
        },

        DisconnectScrollObserver() {
            let element = this.$refs.body;
            element.removeEventListener('scroll', this.GetScrollObserver(element));
        },
        
        GetScrollObserver() {
            let container = this.$refs.body;
            return () => {
                const scrollTop = container.scrollTop;
                const scrollThreshold = 500;

                if (scrollTop <= scrollThreshold) {
                    this.GetConversation(true);
                }
            };
        },

        ScrollToBottom(animate = false) {
            let behavior = animate ? 'smooth' : 'auto';

            this.$refs.body.scrollTo({
                top: this.$refs.body.scrollHeight,
                behavior: behavior
            });
        },

        ClearAttachments() {
            this.files = [];
        },

        GetIsViewedObserver(method, options = { threshold: 0.5 }) {
            return new IntersectionObserver(entries => {
                for (let entry of entries) {
                    method(entry.isIntersecting);
                }
            }, options);
        },

        GetResizeObserver(method) {
            return new ResizeObserver(entries => {
                for (let entry of entries) {
                    const height = entry.contentRect.height;
                    method(height);
                }
            });
        },

        RemoveFile(file) {
            this.files = this.files.filter(f => f !== file);
        },

        AttachmentsChangeHandler(event) {
            let selectedFiles = event.target.files;

            for (let i = 0; i < selectedFiles.length; i++) {
                if(this.files.length >= this.maxAmountOfFiles){
                    ToastService.Error('NO SE PUEDEN AGREGAR MÁS DE ' + this.maxAmountOfFiles + ' ARCHIVOS');
                    break;
                }

                if(!this.allowedMimeTypes[selectedFiles[i].type]){
                    ToastService.Error('EL TIPO DE ARCHIVO DEL DOCUMENTO ' + selectedFiles[i].name + ' NO ES SOPORTADO');
                    continue;
                }

                if(selectedFiles[i].size > this.maxFileSizeInMb * 1024 * 1024){
                    ToastService.Error('EL TAMAÑO DEL DOCUMENTO ' + selectedFiles[i].name + ' SUPERA EL LÍMITE DE ' + this.maxFileSizeInMb + 'MB');
                    continue;
                }

                let isImage = this.allowedMimeTypes[selectedFiles[i].type].isImage;
                if(this.files.length > 0 && this.allowedMimeTypes[this.files[0].type].isImage !== isImage){
                    ToastService.Error('EL ANALISIS DE DOCUMENTOS NO ES COMPATIBLE CON EL ANALISIS DE IMAGENES, POR FAVOR SELECCIONE UN SOLO TIPO DE ARCHIVO');
                    continue;
                }

                this.files.unshift(selectedFiles[i]);
            }

            this.$refs.fileInput.value = '';
        },

        OpenAttachments() {
            this.$refs.fileInput.click();
        },

        ResetSize() {
            this.$parent.$parent.ResetAssistant(false);
        },

        CloseAiAssistant() {
            this.$parent.$parent.ToggleAiAssistant(false);
        },

        async GetConversation(keepScrollPosition = false) {
            if((this.loadingChat && !this.firstChatLoad) || this.endOfChat){
                return;
            }
            
            // Gets the following 25 messages
            let lastMessageId = this.messages.length > 0 ? this.messages[0].id : 0;
            this.loadingChat = true;

            await AssistantService.GetAssistantConversation({ lastMessageId: lastMessageId })
                .then(response => {
                    if(response.length < 25) {
                        this.endOfChat = true;
                        this.DisconnectScrollObserver();
                    }

                    let scrollHeight;
                    if(keepScrollPosition){
                        let container = this.$refs.body;
                        scrollHeight = container.scrollHeight;
                    }

                    // Unshift the messages to the beginning of the array
                    this.messages.unshift(...response);

                    this.$nextTick(() => {
                        if(this.firstChatLoad){
                            this.ScrollToBottom();
                            this.firstChatLoad = false;
                        }

                        if(keepScrollPosition){
                            let container = this.$refs.body;
                            container.scrollTop = container.scrollHeight - scrollHeight;
                        }
                    });
                })
                .catch(error => {
                    ToastService.Error(error.message ? error.message : 'OCURRIÓ UN ERROR AL CARGAR LA CONVERSACIÓN CON EL ASISTENTE');
                })
                .finally(() => {
                    this.loadingChat = false;
                });
        },

        AddAssistantMessage(){
            // So only one message can be processed at a time
            if(this.sendMessageDisabled){
                return;
            }
            
            this.loading = true;

            // Set the file to be sent
            let formData = new FormData();
            this.files.forEach(file => {
                formData.append('files[]', file);
            });

            let scrollToBottom = this.ScrollToBottom;

            let filesToDisplay = this.files.map(x => {
                return {
                    name: x.name,
                    type: this.allowedMimeTypes[x.type].class
                }
            });

            // Add the user message to the chat
            this.messages.push({
                content: this.messageValue,
                isUserMessage: true,
                files: filesToDisplay
            });

            let dataToSend = {
                message: this.messageValue,
            }
            // Clear the message input
            this.messageValue = '';
            // Reset the height of the text area
            this.$refs.messageInput.style.height = '40px';

            // If seeing last message, scroll to the bottom
            if(scrollToBottom){
                this.$nextTick(() => {
                    this.ScrollToBottom();
                });
            }

            AssistantService.AddAssistantMessage(dataToSend, formData)
                .then(response => {
                    response.isUserMessage = false;
                    response.files = [];
                    this.messages.push(response);

                    if(scrollToBottom){
                        this.$nextTick(() => {
                            this.ScrollToBottom();
                        });
                    }
                })
                .catch(error => {
                    ToastService.Error(error.message ? error.message : 'OCURRIÓ UN ERROR AL PROCESAR LA RESPUESTA DEL ASISTENTE');
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    },
};
</script>

<style>
.hiddenObserverDiv {
    height: 1px;
    width: 1px;
    display: block;
}

.PDF {
    background-color: rgb(203, 6, 6);
}

.Word {
    background-color: rgb(41, 84, 151);
}

.Image, .Text {
    background-color: rgb(60, 60, 60);
}

.filesContainer {
    overflow-x: auto;
}

.aiAssistantCentered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.postMessageButton:disabled {
  background-color: rgb(71, 71, 84) !important;
}

.selectedFile {
    background-color: rgb(240, 240, 240);
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 5px;
    min-height: 36px;
    width: max-content;
    font-size: 14px;
    margin-right: 10px;
    border: 1px solid lightgray;
    white-space: nowrap;
}

.removeFileButton {
    margin-left: 10px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid lightgray;
}

.removeFileButton:hover {
    filter: brightness(0.95);
}

.removeFileButton i {
    font-size: 16px;
    margin-right: 0px;
}

.selectedFile .icon {
    margin-right: 10px;
    border-radius: 5px;
    height: 26px;
    width: 26px;
}

.selectedFile .icon i {
    font-size: 18px;
    color: white;
    margin-right: 0px;
}


.filesContainer {
    width: 100%;
    display: flex;
    padding: 10px;
}

.filesContainer.empty {
    padding: 0px;
    height: 0px;
}

.filesContainer::-webkit-scrollbar {
    height: 8px;
}

.messageInputWrapper {
    display: flex;
    width: 100%;
}

.handle {
    border-radius: 50%;
    border: none !important;
}

.chatContainer {
    flex-direction: column;
    max-height: 100vh;
    width: 100%;
    position: relative;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0px 5px 2px rgba(0, 0, 0, 0.2); 
    height: 100%;
}

.footer {
    position: absolute;
    bottom: 0;
    display: flex;
    width: 100%;
}

.messageBar {
    width: calc(100% - 20px);
    max-width: calc(100% - 20px);
    margin: 10px;
    background-color: rgb(245, 245, 245);
    border-radius: 15px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}

.messageBar button {
    margin-top: auto;
}

.header {
    padding: 10px;
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #ccc;
    cursor: move;
}

.buttonWrapper {
    display: flex;
    padding: 5px;
}

.textAreaWrapper {
    flex: 1;
    display: flex;
    align-items: center;
}

.textAreaWrapper textarea {
    border: none;
    outline: none;
    width: 100%;
    max-width: 100%;
    resize: none;
    background-color: transparent;
    height: 40px;
    padding-top: 8px;
    padding-bottom: 8px;
    max-height: 80px;
    pointer-events: auto !important;
    font-size: 14px;
    overflow-x: hidden;
}

.header > button:last-child {
    margin-left: auto !important;
}

.body {
    overflow-y: auto;
    height: calc(100% - 100px);
    padding: 10px;
    border-radius: 5px;
    margin: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    position: relative;
}

.vdr {
    border: none !important;
    position: fixed !important;
    pointer-events: auto !important;

}

.postMessageButton {
    background-color: var(--LativoDarkPurple) !important;
    color: white;
}

.clearFilesButton {
    border: 1px solid lightgray !important;
    margin-right: 5px !important;
}

.assistantLoadingWrapper {
    margin-bottom: 15px;
    height: min-content;
}

.assistantLoading {
    width: 22px !important;
    height: 22px !important;
    border-bottom-width: 2px !important;
    border-top-width: 2px !important;
    border-left-width: 2px !important;
    border-right-width: 2px !important;
}

.goToBottomBurronWrapper {
    position: absolute;
    background-color: transparent;
    pointer-events: none;
}

.goToBottomButton {
    background-color: var(--LativoDarkPurple);
    color: white;
    border-radius: 50%;
    width: 32px;;
    height: 32px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: none;
    cursor: pointer;
    pointer-events: auto;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}

.goToBottomButton i {
    margin-right: 0px;
}

/* Clases para la transición */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.1s ease;
}

.fade-enter, .fade-leave-to /* .fade-leave-active en versiones anteriores de Vue */ {
  opacity: 0;
}

.loading-dots {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-dots div {
  width: 5px;
  height: 5px;
  margin: 0 3px;
  background-color: lightgray;
  border-radius: 50%;
  animation: bounceIn 2s infinite;
}

.loading-dots div:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dots div:nth-child(3) {
  animation-delay: 0.4s;
}

.headerTitle {
    font-size: 14px;
    font-weight: bold;
    color: gray;
}

.helpButtonWrapper {
    position: sticky;
    pointer-events: none;
    height: 0px;
    z-index: 1000;
    margin-top: 0px;
}

.helpButton {
    background-color: rgb(245, 245, 245);
    color: var(--Info);
    border-radius: 50%;
    width: 32px;;
    height: 32px;
    border: none;
    cursor: pointer;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3) !important;
    z-index: 1000;
    pointer-events: auto;

    transform: translateY(10px) translateX(10px);
}

.helpButton i {
    font-size: 16px;
    margin-right: 0px;
}

.body.instructions {
    padding: 15px !important;
}

.body.instructions > div {
    margin-bottom: 20px;
}

.body.instructions > div:last-child {
    margin-bottom: 0px;
}

.body.instructions > .instructionsCard:last-child {
    margin-bottom: 20px;
}

.instructionsBotIconWrapper {
    border-radius: 50%;
    background-color: var(--LativoDarkPurple);
    width: 60px;
    height: 60px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    
}

.instructionsBotIconWrapper img {
    width: 36px;
    height: 36px;
    transform: translateX(2px);
}

.instructionsCard {
    background-color: rgb(245, 245, 245);
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    color: rgb(60, 60, 60);
    font-size: 14px;
}

.instructionsCard i {
    color: var(--Info);
}

.instructionsHeader {
    font-weight: bold;
    margin-bottom: 10px;
    color: var(--LativoDarkPurple)
}

.instructionsHeader i.bi-paperclip {
    display: inline-block;
    transform: rotate(-145deg);
}

.warningsWrapper {
    padding-left: 5px;
    padding-top: 5px;
    font-size: 12px;
}
</style>