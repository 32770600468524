import RequestSender from './RequestSender.js';
import { db } from '@/authentication/firebase.js';
import { collection, addDoc } from "firebase/firestore"; 
import { useStore } from '@/store/DefaultStore.js';


async function PostNotificationUpdate(data, PKOrganization){
    const store = useStore();

    let channel = 'organizationsNotificationsUpdate_' + PKOrganization;
    if(store.env != 'PRD')
        channel = store.env + '_' + channel;

    await addDoc(collection(db, channel), data);
}

function GetNotification(data){
    return RequestSender.Get('notification/GetNotification', data);
}

function GetNotifications(data){
    return RequestSender.Get('notification/GetNotifications', data);
}

function SetNotificationRead(data){
    return RequestSender.Post('notification/SetNotificationRead', data);
}

function GetUnreadNotificationsCount(){
    return RequestSender.Get('notification/GetUnreadNotificationsCount');
}

function GetNotificationCategories() {
    return RequestSender.Get('notification/GetNotificationCategories');
}

function GetNotificationsByCategory(data) {
    return RequestSender.Get('notification/GetNotificationsByCategory', data);
}

function ClearNotificationsByCategory(data) {
    return RequestSender.Post('notification/ClearNotificationsByCategory', data);
}

function GetMessagesNotifications(data) {
    return RequestSender.Get('notification/GetMessagesNotifications', data);
}

export default {
    PostNotificationUpdate, GetNotification, GetNotifications, SetNotificationRead, GetUnreadNotificationsCount, GetNotificationCategories, GetNotificationsByCategory,
    ClearNotificationsByCategory, GetMessagesNotifications
}