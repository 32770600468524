<template>
    <ProfileModal ref="profileModal"/>
    <OrganizationModal ref="organizationModalRef" :modalId="'navbarOrganizationModal'" v-if="currentRoute != 'organizations'"/>

    <div class="lativoNavbar">
        <header>
            <button class="hamburgerButton">
                <i :class="'bi bi-list'" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample"></i>
            </button>

            <img class="logo" src="../../images/LativoLogoWhite.png" @click="GoToHome"/>

            <div v-for="(screen, index) in screens" :key="screen.PKScreen">
                <button v-if="screen.Children.length == 0" class="navbar-item navbar-item-desktop" @click="Redirect(screen.Route, index)"
                    @mouseover="setHovered(index)"
                    @mouseout="setHovered(null)">
                    {{ screen.DisplayName }}
                    <div :class="store.currentPanel != screen.Route && hoveredIndex === index ? 'hovered' : 'notHovered'">
                    </div>
                </button>

                <div v-else class="navbarDropdown">
                    <button
                        @mouseover="setHovered(index)"
                        @mouseout="setHovered(null)"
                        class="navbar-item navbar-item-desktop"
                        type="button"
                        data-mdb-toggle="dropdown"
                        aria-expanded="false">
                        {{ screen.DisplayName }}
                    </button>

                    <div :class="store.currentPanel != screen.Route && hoveredIndex === index ? 'hovered' : 'notHovered'"></div>

                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <li v-for="(child) in screen.Children" :key="child.PKScreen">
                            <a class="dropdown-item" @click="Redirect(child.Route, index)">{{ child.DisplayName }}</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div style="margin-left: auto; display: flex; align-items: center;">
                <div v-if="assistantEnabled && store.Actions.USE_ASSISTANT" class="dropdown assistant">
                    <button class="userButton assistantButton" @click="ToggleAiAssistant(true)">
                        <img src="../../images/BotIcon.png" height="18" width="18"/>
                    </button>
                </div>
                <NotificationsMenu/>
                <ProfileMenu/>
            </div>

        </header>

        <div class="offcanvas offcanvas-start pretorOffCanva" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">

            <div class="returnButton" data-bs-dismiss="offcanvas" aria-label="Close" ref="btnCloseOffcanva">
                <i class="bi bi-arrow-left" style="font-size: 24px; margin-right: 5px;"></i>
            </div>

            <div class="offcanvas-body">
                <div style="padding-top: 0px">

                    <div v-for="screen in screens" :key="screen.PKScreen" class="navbarItemWrapper">
                        <button v-if="screen.Children.length == 0" class="navbar-item mobile" @click="Redirect(screen.Route)">
                            {{ screen.DisplayName }}
                        </button>

                        <div v-else class="childNavbarItemWrapper">
                            <button class="navbar-item mobile childNavbarItem"
                                @click="screen.showChilds ? screen.showChilds = false : screen.showChilds = true" >
                                <label>{{ screen.DisplayName }}</label>

                                <i :class="screen.showChilds ? 'bi bi-chevron-up' : 'bi bi-chevron-down'"></i>
                            </button>

                            <div class="childsWrapper" :class="{ shown: screen.showChilds == true }">
                                <button v-for="(child) in screen.Children" :key="child.name" class="navbar-item mobile" @click="Redirect(child.Route, index)">
                                    {{ child.DisplayName }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="assistantWrapper">
        <vue-draggable-resizable
            v-if="assistantEnabled && store.Actions.USE_ASSISTANT"
            :key="assistantKey"
            ref="aiAssistant"
            :parent="!store.isMobile"
            :drag-handle="'.header'"
            :z="999"
            :x="x"
            :y="y"
            :min-width="300"
            :min-height="300"
            :w="width"
            :h="height"
            :active="true"
            :hidden="!showAiAssistant"
        >
            <AiAssistant/>
        </vue-draggable-resizable>
    </div>
</template>

<script>
import { useStore } from '@/store/DefaultStore.js';
import { useRouter } from 'vue-router';
import NotificationsMenu from './NotificationsMenu.vue';
import { getAuth } from 'firebase/auth';
import { useToast } from 'vue-toastification';
import ProfileMenu from '../Profile/ProfileMenu.vue';
import ProfileModal from '../Profile/ProfileModal.vue';
import OrganizationModal from '@/components/Views/Organizations/OrganizationModal.vue';
import RoleService from '@/services/RoleService';
import PromiseCallerService from '@/services/PromiseCallerService';
import ToastService from '@/services/ToastService';
import vueDraggableResizable from 'vue-draggable-resizable';
import AiAssistant from '@/components/AI-Assistant/AiAssistant.vue';
import AssistantService from '@/services/AssistantService';
import EventBus from '@/utilities/EventBus';

export default{
    name: 'NavBar',
    components: {
        NotificationsMenu, ProfileMenu, ProfileModal, OrganizationModal, vueDraggableResizable, AiAssistant
    },

    setup(){
        const store = useStore();
        const router = useRouter();
        const auth = getAuth();
        const toast = useToast();

        return{
            store, router, auth, toast
        }
    },

    data(){
        return {
            assistantEnabled: false,
            hoveredIndex: null,
            screens: [],
            currentRoute: '',
            aiAssistantObserver: null,
            showAiAssistant: false,
            assistantKey: 0,
            x: 0,
            y: 0,
            width: 300,
            height: 300
        }
    },

    watch: {
        $route(to){
            this.currentRoute = to.name;
        }
    },

    beforeMount() {
        this.ResetAssistant();
    },

    mounted(){
        this.LoadScreens();
        this.GetAssistantIsEnabled();

        EventBus.$on('AssistantEnabledSettingUpdated', this.GetAssistantIsEnabled);
    },

    beforeUnmount(){
        EventBus.$off('AssistantEnabledSettingUpdated', this.GetAssistantIsEnabled);
    },

    methods:{
        GetAssistantIsEnabled() {
            this.store.SetLoader(true);
            PromiseCallerService.CallMethodAsPromise(AssistantService.GetIsAssistantEnabled)
                .then(data => {
                    this.assistantEnabled = data.AssistantEnabled;
                })
                .catch(error => {
                    ToastService.Error(error.message ? error.message : 'OCURRIÓ UN ERROR AL CARGAR EL ASISTENTE')
                })
                .finally(() => {
                    this.store.SetLoader(false);
                });
        },

        ResetAssistant(){
            this.width = this.store.isMobile ? 300 : 400;
            this.height = this.store.isMobile ? 400 : 600;
            this.x = this.store.isMobile ? 0 : 25;
            this.y = this.store.isMobile ? 0 : 25;
            this.assistantKey++;
        },

        ToggleAiAssistant(value = !this.showAiAssistant){
            this.showAiAssistant = value;
            this.defaultWidth = 600;
        },

        LoadScreens() {
            this.store.SetLoader(true);
            PromiseCallerService.CallMethodAsPromise(RoleService.GetUserScreens)
                .then(data => {
                    this.screens = data;
                })
                .catch(error => {
                    ToastService.Error(error.message ? error.message : 'OCURRIÓ UN ERROR AL CARGAR LAS PANTALLAS CON ACCESO')
                })
                .finally(() => {
                    this.store.SetLoader(false);
                });
        },

        GoToHome(){
            if(this.store.isCustomer)
                this.router.push({ name: 'files' })
            else
                this.router.push({ name: 'home' });
        },
        
        EditOrganization()
        {
            this.$refs.organizationModalRef.OpenModal(this.store.CurrentOrganizationPK, true);
        },

        OpenProfileModal(){
            this.$refs.profileModal.OpenModal();
        },

        LogoClickHandler(){
            if(this.store.isCustomer)
                this.router.push({ name: 'files' })
            else
                this.router.push({ name: 'home' });
        },

        Redirect(route, index){
            this.store.currentPanel = index
            this.router.push({ name: route });

            if(this.$refs.btnCloseOffcanva)
                this.$refs.btnCloseOffcanva.click();
        },

        setHovered(index) {
            this.hoveredIndex = index;
        },
    }
}
</script>

<style>
/* Desktop */
@media (min-width: 1200px){
    .hamburgerButton {
        display: none;
    }

    .logo {
        margin-left: 20px;
    }

    .logo img {
        max-width: 140px;
        height: 20px;
    }

    .navbar-item.mobile {
        display: none;
    }

    .userButton img {
        margin-right: 5px;
    }
}

/* Mobile */
@media (max-width: 1200px){
    .userButton.assistantButton {
        margin-right: 0px;
    }

    .logo {
        top: 50%; transform: translate(60px, 0); margin: 0px; width: auto;
    }

    .navbar-item-desktop {
        display: none;
    }

    .childNavbarItemWrapper {
        display: flex;
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
        touch-action: manipulation;
    }

    .childNavbarItemWrapper button {
        width: 100%;
    }

    .childNavbarItem {
        padding-left: 10px !important;
    }

    .childNavbarItem i {
        margin-left: 10px;
    }
    
    .navbarItemWrapper {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

.assistantWrapper {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 999 !important;
    width: 100%;
    height: 100vh;
    pointer-events: none;
}

.pretorOffCanva .offcanvas-body div{
    display: flex; flex-direction: column; padding: 20px; font-size: 20px;
}

.childsWrapper {
    display: none !important;
}

.childsWrapper.shown {
    display: flex !important;
}

.navbarDropdown:hover>.dropdown-menu {
    display: block;
    margin-left: 25px;
    background-color: var(--LativoDarkPurple);
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.dropdown-item {
    height: 36px;
    display: flex;
    align-items: center;
}

.dropdown-item:hover {
    background-color: var(--LativoDarkPurple);
}

.dropdown-menu > li > a:hover {
    color: lightgray;
}

.dropdown-menu > li > a {
    color: white;
    font-size: 15px;
}

.navbarDropdown>.dropdown-toggle:active {
    pointer-events: none;
}

.lativoNavbar {
    position: sticky; top: 0px; z-index: 996 !important;
}

.lativoNavbar header {
    background-color: var(--LativoDarkPurple);
    color: white;
    width: 100%;
    height: 60px;
    display: flex;
    position: sticky;
    top: 0px;
    align-items: center;
}

.logo img{
    max-width: 140px;
    margin: 0px;
}

.logo{
    height: 20px;
    cursor: pointer;
    padding: 0px;
}

.hamburgerButton {
    background-color: transparent; border: none; position: absolute; left: 0px; margin-left: 10px;
}

.hamburgerButton i {
    color: rgb(245, 245, 245); font-size: 30px !important;
}

.pretorOffCanva{
    background-color: transparent;
    border: none !important;
    width: 100% !important;
}

.pretorOffCanva .returnButton{
    color: white;
    position: absolute !important;
    top: 15px !important;
    left: 25px !important;
    background-color: none;
    padding-top: 0px;
    cursor: pointer;
}

.pretorOffCanva .offcanvas-body{
    background-color: rgb(31, 31, 44, 0.9);
    color: white;
}

.pretorOffCanva div{
    padding-top: 80px;
}

.navbar-item{
    margin-left:30px;
    background-color: transparent;
    font-size: 14px;
    height: 60px;
    border-radius: 10px;
    border: none;
    color: white;
    cursor: pointer;
}

.navbar-item div {
    display: block; height: 1px;background-color: white;
}

.navbar-item.mobile{
    margin-left: 0px !important;
    font-size: 18px !important;
    margin-bottom: 30px !important;
}

.userButton{
    background-color: transparent;
    border: none;
    color: white;
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 15px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 15px;

    position:relative;
    margin-left: auto;
    height: 40px;
}

.userButton.mobile{
    margin-right: 0px !important;
    border-radius: 50%;
    width: 40px;
}

.userButton:hover{
    background-color: rgb(40, 40, 40);
}

.userButton img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
}

.userButton.assistantButton img {
    height: 18px;
    width: 18px;
    border-radius: 0;
    margin-right: 0px;
}

.userButton i{
    font-size: 18px;
}

.userButton.mobile i{
    margin-right: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.userButtonMenu{
    cursor: pointer;
    background-color: var(--LativoDarkPurple);
    color: white;
    padding-left: 15px;
    padding-right: 15px;
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
}

.userButtonMenu:hover{
    background-color: rgb(62, 62, 62);
}

.hovered{
    animation: startHover 0.3s forwards;
}

.notHovered{
    animation: endHover 0.3s forwards;
}


@keyframes startHover{
    from{
        opacity: 0;
        width: 0;
    }
    to{
        opacity: 1;
        width: 100%;
    }
}

@keyframes endHover{
    from{
        opacity: 1;
        width: 100%;
    }
    to{
        opacity: 0;
        width: 0;
    }
}

</style>