import RequestSender from './RequestSender.js';

function AddOrganization(organization, files) {
    return RequestSender.PostFormData('organization/AddOrganization', organization, files);
}

function GetAllOrganizations(){
    return RequestSender.Get('organization/GetAllOrganizations');
}

function GetOrganizationDetail(data){
    return RequestSender.Get('organization/GetOrganizationDetail', data);
}

function EditOrganization(data, files){
    //return RequestSender.Post('organization/EditOrganization', data);\
    return RequestSender.PostFormData('organization/EditOrganization', data, files);
}

function EnableOrganization(data){
    return RequestSender.Post('organization/EnableOrganization', data);
}

function DisableOrganization(data){
    return RequestSender.Post('organization/DisableOrganization', data);
}

export default {
    AddOrganization, GetAllOrganizations, GetOrganizationDetail, EditOrganization, EnableOrganization, DisableOrganization
}