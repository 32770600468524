<template>
    <div v-if="store.isLoading" class="spinnerDiv">
        <div class="spinner-border text-light">
        </div>
    </div>
</template>

<script>
import { useStore } from '../../store/DefaultStore.js';

export default{
    name: 'LoadingScreen',
    setup(){
        const store = useStore();

        return{
            store
        }
    }
}
</script>

<style>
.spinnerDiv{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.5);
    z-index: 9999 !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>