import Cookies from "js-cookie";
import { getAuth, signOut } from 'firebase/auth';
import { useStore } from '@/store/DefaultStore.js';
import router from '@/router/index.js';

function ExceedsInactivityTime(){
    let allowedInactivityHours = 6;

    let lastActivity = Cookies.get('lastActivity');

    if(!lastActivity)
        return false;

    let currentTime = (new Date()).getTime();
    let lastActivityTime = parseInt(lastActivity);
    
    return currentTime >= lastActivityTime + (allowedInactivityHours * 3600000);
}

async function AutoRefreshToken(user){
    if(ExceedsInactivityTime()){
        LogOut();
        const store = useStore();
        store.ReloadErrorMessage = 'LA SESIÓN SE HA CERRADO POR EXCESO DE INACTIVIDAD';
        router.push('/login');
        return;
    }

    if(user == null){
        LogOut();
        throw new Error('EL USUARIO NO SE HA AUTENTICADO');
    }

    let refreshedToken = await user.getIdToken(true);

    SetMFA();
    SetAccessToken(refreshedToken, user);
}

function SetMFA(){
    var expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + 3600000);

    Cookies.set('MFA', 'true', { expires: expirationDate });
}

async function GetMFA(){
    return Cookies.get('MFA');
}

function SetAccessToken(accessToken, user){
    var expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + 3600000);

    Cookies.set('accessToken', accessToken, { expires: expirationDate });

    //Refresca la sesion cada 55 mins
    setTimeout(() => {
        AutoRefreshToken(user);
    }, 3540000);
}


function LogOut(){
    const store = useStore();

    Cookies.remove('accessToken');
    Cookies.remove('lastActivity');
    Cookies.remove('MFA');
    const auth = getAuth();
    signOut(auth);
    store.LoggedUser = null;
}

async function GetAccessToken(){
    if(ExceedsInactivityTime()){
        LogOut();
        const store = useStore();
        store.ReloadErrorMessage = 'LA SESIÓN SE HA CERRADO POR EXCESO DE INACTIVIDAD';
        router.push('/login');
        return;
    }

    Cookies.set('lastActivity', (new Date()).getTime());

    return Cookies.get('accessToken');
}

function SetTemporalAccessToken(accessToken){
    Cookies.set('accessToken', accessToken, { expires: 1/24 });
}

function SetCurrentOrganization(PKOrganization){
    Cookies.set('currentOrganization', PKOrganization);
}

function GetCurrentOrganization(){
    let currentOrganization = Cookies.get('currentOrganization');

    return currentOrganization ? parseInt(currentOrganization) : null;
}

export default {
    SetAccessToken, GetAccessToken, LogOut, AutoRefreshToken, SetCurrentOrganization, GetCurrentOrganization, SetTemporalAccessToken, SetMFA, GetMFA
}