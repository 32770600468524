function CallAsPromise(func, data) {
    return new Promise((resolve, reject) => {
        func(data)
        .then(response => {
            resolve(response);
        })
        .catch(error => {
            reject(error);
        });
    })
}

export default { CallAsPromise }
